.messages {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.message {
    border: 1px solid rgb(112, 104, 104);
    margin-bottom: 10px;
    padding: 5px;
}