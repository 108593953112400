@font-face {
    font-family: 'Roboto Regular'; // 400
    src: url('assets/fonts/Roboto-Regular.eot');
    src: url('assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Roboto-Regular.woff') format('woff'),
    url('assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
  
* {
  font-family: "Roboto Regular", sans-serif;
  box-sizing: border-box;
}
  
body {
  box-sizing: border-box;
  background-color:rgb(249, 242, 245);
}